import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    beforeEnter: () => {
      if (localStorage.getItem("_token") == null) {
        router.push("/auth/signin");
        return;
      }
    },
    component: () => import("../views/Dashboard/DashboardView.vue"),
    children: [
      {
        path: "home",
        name: "dashboard.home",
        component: () => import("../views/Dashboard/WelcomeView.vue"),
      },
      {
        path: "products",
        name: "dashboard.products",
        component: () => import("@/components/Dashboard/ProductPage.vue"),
      },
      {
        path: "product/edit/:slug",
        name: "dashboard.product.edit",
        component: () => import("@/components/Dashboard/ProductEdit.vue"),
      },
      {
        path: "categories",
        name: "dashboard.categories",
        component: () => import("@/components/Dashboard/CategoriesPage.vue"),
      },

      {
        path: "category/edit/:slug",
        name: "dashboard.category.edit",
        component: () => import("@/components/Dashboard/CategoryEdit.vue"),
      },
      {
        path: "companies",
        name: "dashboard.companies",
        component: () => import("@/components/Dashboard/CompanyPage.vue"),
      },
      {
        path: "company/edit/:slug",
        name: "dashboard.companies.edit",
        component: () => import("@/components/Dashboard/CompanyEdit.vue"),
      },
      {
        path: "orders",
        name: "dashboard.orders",
        component: () => import("@/components/Dashboard/OrdersPage.vue"),
      },
      {
        path: "logs",
        name: "dashboard.logs",
        component: () => import("@/components/Dashboard/LogsPage.vue"),
      },
      {
        path: "reviews",
        name: "dashboard.review",
        component: () => import("@/components/Dashboard/ReviewList.vue"),
      },
    ],
  },
  {
    path: "/auth",
    children: [
      {
        path: "signin",
        name: "auth.login",
        beforeEnter: () => {
          if (localStorage.getItem("_token") != null) {
            router.push("/dashboard/home");
            return;
          }
        },
        component: () => import("@/components/SignIn.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "Home",
    beforeEnter: () => {
      router.push("/auth/signin");
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
