export default {
  sidebar: {
    home: "Home",
    product: "Products",
    category: "Categories",
    company: "Company",
    orders: "Orders",
    review: "Reviews",
    logs: "Warehouse",
    profile: "Profile",
    setting: "Settings",
    logout: "Logout",
  },
  features: {
    user: "Total Users",
    store: "Total Store",
    income: "Income",
    order: "Orders",
    week: "Last Week Performance",
    update: "Last update",
    no: "No.",
    orderno: "Order No.",
    client: "Client",
    phone: "Client's Phone",
    email: "Email",
    status: "Status",
    date: "Date",
    yet: "There is no orders yet !",
    action: "Actions",
    search: "Search",
  },
  product: {
    new: "Add new product",
    no: "No.",
    name: "Name",
    price: "Price",
    category: "Category",
    company: "Company",
    discount: "Discount",
    qty: "Quantity",
    sells: "Total Sells",
    action: "Actions",
    edit: "Edit",
    visibility: "Save",
    vbtn: "Change Availability",
    delete: "Delete",
    en_name: "Product Name(En)",
    ar_name: "Product Name(Ar)",
    en_var: "Product Variant(En)",
    ar_var: "Product Variant(Ar)",
    en_desc: "Product Description(En)",
    ar_desc: "Product Description(Ar)",
    scat: "Select Category",
    scom: "Select Company",
    cover: "Product Cover",
    images: "Product Images",
    create: "Create",
    save: "Save Product",
    confirm: "Confirm Operation",
    subtitle: "Delete Product",
    message: "Are you sure you want to change product visibility for",
  },
  category: {
    no: "No.",
    name: "Name",
    action: "Actions",
    title: "Create new category",
    en_name: "Category Name(En)",
    ar_name: "Category Name(Ar)",
    create: "Create",
    edit: "Editing category",
    save: "Save Category",
    confirm: "Confirm Operation",
    subtitle: "Delete Product",
    message: "Are you sure you want to delete",
  },
  company: {
    no: "No.",
    name: "Name",
    action: "Actions",
    title: "Create new company",
    en_name: "Company Name(En)",
    ar_name: "Company Name(Ar)",
    create: "Create",
    edit: "Editing company",
    save: "Save company",
    confirm: "Confirm Operation",
    subtitle: "Delete Product",
    message: "Are you sure you want to delete",
  },
  review: {
    choose: "Please choose a product",
    noreview: "There is no reviews for this product yet!",
    dsiplay: "Displaying all reviews for product",
  },
  profile: {
    fname: "First Name",
    lname: "Last Name",
    email: "Email Address",
    password: "Password",
    edit: "Edit Profile",
    ptitle: "Profile Edit",
  },
  settings: {
    title: "Edit Settings",
    en_name: "Store Name(En)",
    ar_name: "Store Name(Ar)",
    en_hero: "Hero Title(En)",
    ar_hero: "Hero Title(Ar)",
    bg: "Hero Background",
    en_ads: "Ads Title(En)",
    ar_ads: "Ads Title(Ar)",
    ads1: "Ads Image 1",
    ads2: "Ads Image 2",
    en_section: "Additional Section Title(En)",
    ar_section: "Additional Section Title(Ar)",
    en_desc: "Additional Section Description(En)",
    ar_desc: "Additional Section Description(Ar)",
    image: "Image",
    email: "Store Email Address",
    phone: "Store Phone Number",
    en_address: "Store Address(En)",
    ar_address: "Store Address(Ar)",
    lat: "Map Laitude",
    lan: "Map Longtiude",
    info: "Social Media Info",
    facebook: "Facebook",
    yt: "Youtube",
    x: "X",
    ig: "Instagram",
    tk: "Tiktok",
    sc: "Snapchat",
    save: "Edit Settings",
  },
  signin: {
    title: "Welcome Back !",
    email: "Email Address",
    password: "Password",
    login: "Sign in",
  },
  orders: {
    title: "Edit Status",
    edit: "Edit status",
    select: "Select Status",
    name: "Product",
    qty: "Quantity",
    price: "Price",
    details: "Showing details for order",
    sub: "Subtotal",
    pay: "Payment Method",
  },
  messages: {
    success: "Successfully saved changes!",
    error: "Something went wrong please try again!",
  },
  logs: {
    pname: "Product Name",
    desc: "Description",
    date: "Date",
    qty: "Remaning Quantity",
  },
};
