export default {
  sidebar: {
    home: "الرئيسة",
    product: "المنتجات",
    category: "الفئات",
    company: "الشركات",
    orders: "الطلبات",
    review: "التقيمات",
    logs: "المخزون",
    profile: "الملف الشخصي",
    setting: "الاعدادات",
    logout: "تسجيل الخروج",
  },
  features: {
    user: "عدد المستخدمين",
    store: "عدد المتاجر",
    income: "الربح",
    order: "الطلبات",
    week: "مخطط اخر اسبوع",
    update: "اخر تحديث في",
    no: "رقم.",
    orderno: "رقم الطلب",
    client: "العميل",
    phone: "رقم هاتف العميل",
    email: "البريد الالكتروني",
    status: "الحالة",
    date: "التاريخ",
    yet: "لايوجد طلبات",
    action: "خيارات",
    search: "بحث",
  },
  product: {
    new: "اضافة منتج جديد",
    no: "الرقم.",
    name: "اسم المنتج",
    price: "السعر",
    category: "الفئة",
    company: "الشركة",
    discount: "الخصم",
    qty: "الكمية",
    sells: "عدد المبيعات",
    action: "خيارات",
    edit: "تعديل",
    visibility: "حفط",
    vbtn: "التوافر ",
    delete: "حذف",
    en_name: "اسم المنتج بالانجليزية",
    ar_name: "اسم المنتج بالعربية",
    en_var: "Product Variant(En)",
    ar_var: "Product Variant(Ar)",
    en_desc: "وصف المنتج بالانجليزية",
    ar_desc: "وصف المنتج بالعربية",
    scat: "اختيار الفئة",
    scom: "اختر الشركة",
    cover: "صورة غلاف المنتج",
    images: "صور المنتج",
    create: "انشاء جديد",
    save: "حفظ المنتج",
    confirm: "تاكيد العملية",
    subtitle: "عملية الحذف",
    message: "هل انت متاكد من حذف المنتج",
  },
  category: {
    no: "رقم.",
    name: "اسم الفئة",
    action: "الخيارات",
    title: "انشاء فئة جديدة",
    en_name: "اسم الفئة بالانجليزية",
    ar_name: "اسم الفئة بالعربية",
    create: "انشاء",
    edit: "تعديل الفئة",
    save: "حفظ الفئة",
    confirm: "تاكيد العملية",
    subtitle: "عملية الحذف",
    message: "هل انت متاكد من حذف الفئة",
  },
  company: {
    no: "رقم.",
    name: "اسم الشركة",
    action: "الخيارات",
    title: "انشاء شركة جديدة",
    en_name: "اسم الشركة بالانجليزية",
    ar_name: "اسم الشركة بالعربية",
    create: "انشاء",
    edit: "تعديل الفئة",
    save: "حفظ الفئة",
    confirm: "تاكيد العملية",
    subtitle: "عملية الحذف",
    message: "هل انت متاكد من حذف الشركة",
  },
  review: {
    choose: "الرجاء اختيار منتج",
    noreview: "لايوجد تقيمات لهذا المنتج",
    dsiplay: "يتم عرض جميع التقيمات للمنتج المختار",
  },
  profile: {
    fname: "الاسم الاول",
    lname: "الاسم الاخير",
    email: "البريد الالكتروني",
    password: "كلمة المرور",
    edit: "تعديل الملف الشخصي",
    ptitle: "تعديل البيانات",
  },
  settings: {
    title: "تعديل الاعدادت",
    en_name: "اسم المتجر بالانجليزية",
    ar_name: "اسم المتجر بالعربية",
    en_hero: "وصف االمتجر بالانجليزية",
    ar_hero: "وصف المتجر بالعربية",
    bg: "غلاف الصفحة الرئيسية",
    en_ads: "عنوان الاعلان بالانجليزية",
    ar_ads: "عنوان الاعلان بالعربية",
    ads1: "صورة الاعلان 1",
    ads2: "صورة الاعلان 2",
    en_section: "عنوان القسم الاضافي بالانجليزية",
    ar_section: "عنوان القسم الاضافي بالعربية",
    en_desc: "وصف القسم الاضافي بالانجليزية",
    ar_desc: "وصف القسم الاضافي بالعربية",
    image: "صورة القسم",
    email: "بريد المتجر الالكتورني",
    phone: "رقم جوال المتجر",
    en_address: "عنوان المتجر بالانجليزية",
    ar_address: "عنوان المتجر بالعربية",
    lat: "خط العرض للخريطة",
    lan: "خط الطول للخريطة",
    info: "حسابات التواصل الاجتماعي",
    facebook: "Facebook",
    yt: "Youtube",
    x: "X",
    ig: "Instagram",
    tk: "Tiktok",
    sc: "Snapchat",
    save: "حفظ الاعدادات",
  },
  signin: {
    title: "مرحبا بعودتك",
    email: "البريد الالكتروني",
    password: "كلمة المرور",
    login: "تسجيل الدخول",
  },
  orders: {
    title: "تعديل حالة الطلب",
    edit: "تعديل",
    select: "الرجاء اختيار الحالة",
    name: "المنتج",
    qty: "الكمية",
    price: "السعر",
    details: "عرض تفاصيل الطلب ",
    sub: "الاجمالي",
    pay: "طريقة الدفع",
  },
  messages: {
    success: "تم حفظ التغيرات بنجاح !",
    error: "حدث خطأ ما يرجى اعادة المحاولة !",
  },
  logs: {
    pname: "المنتج",
    desc: "الوصف",
    date: "التاريخ",
    qty: "المخزون المتبقي",
  },
};
